<template>
    <div class="home">
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in lunboList" :key="index">
                <img class="van-swipe-item-img" v-lazy="item.image" />
            </van-swipe-item>
        </van-swipe>
        <div class="rich-main w1330" v-html="detail.content"></div>
    </div>
</template>
  
<script>

export default {
    name: 'Home',
    data() {
        return {
            lunboList: [],
            id: '',
            detail: {}
        }
    },
    created() {
        this.id = this.$route.query.id
        this.getDetail()
    },
    methods: {
        //获取详情
        async getDetail() {
            var res = await this.$api.Get(`index/importantEvents?ids=${this.id}`)
            this.lunboList = res.data.bgimage.split(',').map(item => {
                return {
                    image: item
                }
            })
            this.detail = res.data
        }
    }
}
</script>
<style lang="less" scoped>
.van-swipe-item-img {
    display: block;
    width: 100%;
    height: calc(100vh - 71px);
    object-fit: cover;
}


@media screen and (max-width:750px) {
    .van-swipe-item-img {
        height: auto;
    }

    .rich-main {
        width: 100%;
        padding: 10px;
    }
}
</style>
